/* Login Page Code */

.login-page .card-header{display:flex;align-items:center;justify-content:center;background-color:transparent;padding:0}
.login-page .card{border-radius:20px;overflow:hidden;box-shadow:0 0!important;background-color:transparent}
.login-page .card-body{background-color:transparent}
.login-page .card-header .font-weight-light{text-transform:capitalize;color:#fff!important;background-color:#EE3124;padding:2px 15px 5px;border-radius:30px;min-height:30px;display:flex;align-items:center;justify-content:center;line-height:normal;margin:10px 0 0}
.login-page{background:#4a100c}
.login-page .card-body .card-body.text-black{padding:0}
.login-page .card-body .ui.form .field>label{color:#fff}
.login-page .card-header .login-head-logo {display: flex;width: calc(100% - 30px);justify-content: center;margin: 0 0 10px;}
.login-page .card-header .login-head-logo img {height: 34px;width: auto;}
.login-page .card-header {border-bottom: 0;}

.login-page main{height:100vh}
.login-page main .container{height:100%}
.login-page main .justify-content-center{align-items:center!important;justify-content:center!important;height:100%}
.login-page main .justify-content-center .ui.button{border-radius:10px!important}

.sap-btn-dark{background-color:#EE3124!important;border-radius:50px!important;min-height:50px!important}
.validation-error {color: red;text-transform: uppercase;font-size: 10px;letter-spacing: .3px;font-weight: 500;position: relative;top: -10px;left: 3px;}



/* Login Page Code */

/*Home page content*/


h1.ui.button2 {
    background-color: #EE3124;
    border-radius: 50px;
    color: #fff !important;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .5px;
    font-weight: 600;
    min-width: 150px;
    padding: 8px 12px;
    min-height: 45px;
    transition: all .3s ease-in-out;
    text-decoration: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 !important;
    outline: 0 !important;
}

.icons{    cursor: pointer;}

.main-heading {
    display: block;
    margin: 0 auto;
    width: 30%;
}
.form-sub-main {
    display: flex;
    margin: 0 auto;
    gap: 20px;
    justify-content: center;
}


.right-nav .modal-title {
    font-weight: 600;
    color: #fff !important;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
}

.homepage_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

#dash-event .form-group label, .form-group label b {
    text-align: center;
    color: #Fff;
}
#dash-event input.form-control {
    text-align: center;
}
.sub_heading_form {
    width: 25%;
}

.sub_heading-formgroup {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.modal_title_form {
    width: 50%;
    margin: 0 auto;
}

textarea.form-control {
    text-align: center;
}


form.homepage_mains {
 /*   background-image: url(../src/image/section1.1.png);*/
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    padding: 50px 15px;
    background-color: #888;
}

.section_two {
/*    background-image: url(../src/image/section2.2.png);*/
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    margin-top: 50px;
    padding: 50px 15px;
     background-color: #888;
}

.ReactModal__Content.ReactModal__Content--after-open {
    box-shadow: 0 0 25px #00000082;
}

.ReactModalPortal select{
    width: 100%;
    height: 30px;
    padding: 5px;
    color: #f62a2a;
    border-radius: 8px;
    border: 0px solid #000 !important;
  }
.ReactModalPortal select option { color: black; 
    border: 0px solid #000 !important;
}
#layoutSidenav_content thead, tbody, tfoot, tr, td, th {
    border-color: #E1E1E1;
    border-style: solid;
    border-width: 1px;
}


.admin-icons {
    font-size: 20px;
}


/* .ReactModal__Content::-webkit-scrollbar {
  
	display:none;
  }
   */

.ReactModal__Content .form-group {
    position: relative;
}
              
/******************Faq CSS*****/

#dash-faq table tbody tr {cursor: n-resize;}
#dash-faq table tr td:first-child:before {content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 320 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M40 352l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zm192 0l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 320c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 192l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 160c-22.1 0-40-17.9-40-40L0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40z'/%3E%3C/svg%3E");position: relative;display: inline-flex;align-items: center;justify-content: center;position: relative;margin: 0 10px 0 0px;top: 3px;opacity: 0.3;}
#dash-faq .buy-loads-outer-bottom, #InsufficientLoadModal .buy-loads-outer-bottom {display: grid;grid-template-columns: repeat(2,1fr);grid-column-gap: 20px;grid-row-gap: 30px;}
#InsufficientLoadModal .modal-content {padding: 0;}
#InsufficientLoadModal form {padding: 0;}
.package-head, .package-head .fam-package {width: 100%;}
.ind-package .sap-blue-btn button, .fam-package .sap-blue-btn button {min-width: 100% !important;}
#dash-faq table thead th:first-child {min-width: 50px;}

#orders-list .modal-footer {
    justify-content: flex-start;
}
#dry-cleaning .buy-loads-outer-bottom {
    position: relative;
}

#dry-cleaning .spinner-border {
    position: absolute;
    top: 85%;
    left: 45%;
}

#layoutSidenav_nav .accordion {
    position: relative;
}

#layoutSidenav_nav .spinner-border {
    position: absolute;
    top: 85%;
    left: 45%;
    width: 20px !important;
    height: 20px !important;
}


#users-list .users-list-table {
    position: relative;
}

#users-list .spinner-border {
    position: absolute;
    top: 85%;
    left: 45%;
}

#users-list button.edit-btn {
    background: #17a2b8;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 6px;
    padding: 7px 10px 9px;
}
#users-list button.del-instruct {
    background: #b37659;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 6px;
    padding: 7px 10px 9px;
}
#users-list .dual-category{
    display: flex;
    align-items: center;
}

#users-list .dual-category .dlt-btn {
    background: #ad252a;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    margin-left: 36px;
    padding: 9px 10px 10px;
    min-width: 130px;
    font-weight: 500;
}
#users-list .dual-category .dlt-btn i {
    margin-left: 4px;
}
.edit-pack, .del-pack{
    cursor: pointer;
}


#dry-cleaning .right-nav {
    padding: 50px 0px;
}




.faqs .font-42 {
    margin-bottom: 20px;
}

.faqs .customer-title, .faqs .drop-title, .faqs .pick-title, .faqs .driver-title {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 6px;
}

.faqs .table-responsive {
    background: #f1f0f0 !important;
    margin-bottom: 33px;
}

.faqs table tbody td:first-child {
    display: flex;
}

#email-temp .card {
    background-color: #f2f2f2;
    border-color: rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin: 0 0 30px;
}

#email-temp table {
    width: 100% !important;
}

#email-temp .card-title {
    padding: 10px;
    background-color: #0071bd;
    color: #fff;
    margin: 0 0 20px;
    border-radius: 5px;
}

#email-temp .card .ad-pk-icn {
    justify-content: flex-end;
}

#email-temp .card .ad-pk-icn i {
    color: #0071bd;
}

.modal-inner-content{
    margin: 0px 40px;
}

.modal-inner-content .modal-footer {
    padding: 10px 0 10px;
}


#layoutSidenav_nav a.nav-link.active {
    color: red;
}

/********Group-list*********/

.admin-groups-inner {
    display: flex;
    gap: 21px;
}
.admin-groups-inner .card-body {
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.admin-groups-inner .card {
    min-width: 218px;
    height: auto;
    max-width: 218px;
    width: 100%;
    border-radius: 10px;
    background: #F6F6F6;
    border: none;
}
.admin-groups-inner .group-img img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
    border-radius: 50px !important;
    border: 2px solid #FFF;
    object-position: top;
}
.admin-groups-inner .group-img {
    height: 62px;
    max-width: 62px;
    margin: 0 auto;
    width: 100%;
}

.admin-groups-inner .heading-title {
    color: #0071BD;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-transform: capitalize;
    padding-bottom: 11px;
}
.admin-groups-inner .card-text {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-transform: capitalize;
}
.admin-groups-inner .edit-pack {
   cursor: pointer;
    justify-content: right;
    display: flex;
    padding: 0px 0px 20px;
    position: absolute;
    top: 20px;
    right: 20px;
}


.admin-groups-inner .txt-ctr {
    text-align: center;
    padding: 14px 0px 0px;
}


/****Auto suggestions**/

.group-modal .modal-dialog {
    max-width: 795px;
}

.folder-modal .modal-dialog {
    max-width: 795px;
}
#admin-folders-list .edit-btn{cursor: pointer;}
.group-modal .form-control {
    border-radius: 60px !important;
    border: 1px solid #D5D5D5;
    background: #FFF;
    height: 51px;
}

.group-modal .form-control::placeholder{color: rgba(117, 117, 117, 0.45);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 16px; 
text-transform: capitalize;
}

.group-modal .service_img img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}
.group-modal .service_img {
    max-width: 121px;
    height: 102px;
    width: 100%;
}

.group-modal label {
    color: #333 !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    text-transform: capitalize !important;
    padding-bottom: 10px !important;
}

.close_eye {
    position: absolute;
    bottom: 25px;
    right: 15px;
    width: 18px;
    height: 14px;
    cursor: pointer;
}
.password-eye{position: relative;}
.react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px 10px 40px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 300px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  
  .inputContainer {
    position: relative;
  }
  
  .inputContainer .icon {
    position: absolute;
    top: 3px;
    left: 10px;
    width: 24px;
    height: 24px;
  }


  .accordion-button.sub-menus:after {
    visibility: visible !important;
}

.font-12.txt-combo {
    font-size: 12px;
    color: #625e5e;
}

/**********#admin-roles-permissions search-bar********/
#admin-roles-permissions .searc-bar {
    margin: 12px 0 0 0;
    position: absolute;
    top: 9px;
    right: 18px;
    width: 100%;
    
}

    
#admin-roles-permissions .assign-permissions {
    padding: 55px 0px;
}
#admin-roles-permissions .modal-footer{
    display:flex;
    justify-content: start;
}
#admin-roles-permissions .sap-admin-title {
    padding-top: 18px;
    width: 100%;
}
/**************/

button.visit-btn {
    background: #6e6765;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 6px;
    padding: 7px 10px 9px;
}


#users-list .select-category label{
    color: #2d2d2d;
    display: block;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .5px;
    margin: 0 0 4px;
    text-transform: uppercase;
    width: 100%;
    padding: 0 0 5px 0;
}

#send-mail .highlightOption {background: #ad252a;}
#send-mail .multiSelectContainer li:hover {background: #ad252a;}
#send-mail .chip {background: #ad252a;}

#county-modal .highlightOption {background: #ad252a;}
#county-modal .multiSelectContainer li:hover {background: #ad252a;}
#county-modal .chip {background: #ad252a;}

.uncheck {
    background-color: #fff !important;
    border: 1px solid #0474ba;
}
#order_detail_cont .dual-btns button.btn.btn-primary {
    padding: 8px;
    background: #d62a2a;
    color: white;
    margin: 0 10px 0 0;
    border-radius: 7px;
}
#order_detail_cont .dual-btns a {
    padding: 10px;
    background: #d62a2a;
    color: white;
    margin: 0 10px 0 0;
    border-radius: 7px;
    font-weight: bold;
}

div#action-modal {
    min-width: 60%;
}
#action-modal .modal-content {
    min-height: 165px;
}
#action-modal .swc-dual-btn button {
    background-color: #ad252a;
}
#action-modal .active-status{
    background-color: #0071bd !important;
}
#delivery_instruct textarea.form-control {
    text-align: left;
    margin: 20px 0 0 0;
}
#action-modal textarea.form-control {
    text-align: left;
    margin: 10px 0 0 0;
}


#delivery_instruct .swc-dual-btn button {
    background-color: #ad252a;
}
#delivery_instruct .active-status{
    background-color: #0071bd !important;
}


.service_img .edit_circle{
    top: 200px;
    left: 250px;
}
@media(max-width: 767px) {
#dash-faq .buy-loads-outer-bottom, #InsufficientLoadModal .buy-loads-outer-bottom {grid-template-columns: repeat(1,1fr);grid-row-gap: 30px;}
#dash-faq .buy-loads-outer-bottom .upfront_amount, #InsufficientLoadModal .buy-loads-outer-bottom .upfront_amount {margin-bottom: 0;}
#dash-faq .buy-loads-outer-bottom .ind-package, #InsufficientLoadModal .buy-loads-outer-bottom .ind-package {margin-top: 45px;width: 100%;}


}


/*********************************/

@media(max-width: 575px) {
    #admin-roles-permissions .searc-bar input {width: 87%;}
    #admin-roles-permissions .searc-bar {top: 68px;right: 26px;width: 100%;}
    #admin-roles-permissions .assign-permissions {padding: 97px 0px;}
}
@media(max-width: 374px) {
    #admin-roles-permissions .searc-bar {top: 68px;right: 16px;width: 100%;}

}



@media(min-width:1200px)
{
.sb-topnav.navbar-dark #sidebarToggle{display: none;}
}


/*********************************/



#admin-roles-permi .card-title {
    padding: 0px 0px 20px;
}
.admin-roles-permissions{
    padding: 20px 0px 20px;
}
.admin-roles{
    padding: 12px 0px 12px;
}
.permissions-check-p-head{
    padding: 0px 10px 20px;
    font-size: 16px;
}
.permissions-check-p-inner input[type="checkbox"] {
    margin-right: 10px;
}


#orders-list .swc-rating img {
    padding: 1px;
}

#order_detail_cont .hist_star_inner img {
    padding: 1px;
}









#shadow-host-companion {padding: 0;}
.extra-edit-charge {
    text-align: right;
    padding: 11px 15px 0px;
    cursor: pointer;
}
/*Extra charges card*/
.choose-cate-sap .modal-footer {
    display: flex;
    justify-content: space-between;
}
#packages .modal-footer {
    display: flex;
    justify-content: space-between;
}
.min-submit-btn.card {
    border-radius: 20px !important;
    /* border: 1px solid #d5d0d1; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    min-width: 250px;
}
.min-submit-btn.card .card-body {
    display: flex;
    padding-top: 7px;
    font-weight: 600;
    justify-content: space-between;
}
.min-submit-btn.card .modal-footer {
    justify-content: space-between;
}
.min-submit-btn.card .extra-edit-charge i {
    font-size: 16px;
    color: #ad252a;
}
.min-submit-btn.card .card-text {
    font-size: 16px;
}
.min-submit-btn.card .card-title {
    font-size: 16px;
    padding: 0px 14px 0px 0px;
}


#admin-roles .modal-footer {
    border-top: none;
    margin: 0 auto;
    position: absolute;
    top: 0;
    right: 19px;
    bottom: 375px;
}

#admin-roles{position: relative;}


#admin-roles .searc-bar {
    margin: 12px 0 0 0;
    display: flex;

}

#admin-roles .searc-bar input {
    width: 34%;
    margin: 5px 0px 25px 0px;
}



/*******Badhes bg colors*****/

.reside_cat{background-color:#4d904c !important;}
.commerc_cat{background-color:#c35c8d !important;}
.yeshiv_cat{background-color:#034b7a !important;}

#orders-list .ne_o{background-color:#b52469 ; }
#orders-list .wa_h{background-color:#034b7a ;}
#orders-list .dr_y{background-color:#3a5f61 ;}  
#orders-list .fo_d{background-color:#fd6500  ;} 
#orders-list .way_drop{background-color:#b1a802 ;}
#orders-list .no_sts{background-color:#373431 ;}  
#orders-list .pick_up{background-color:#840863 ;}
#orders-list .packed_up{background-color:#d792bf ;}
#orders-list .compl_up{background-color:#3ebd1e ;}

#users-details .ne_o{background-color:#b52469 ; }
#users-details .wa_h{background-color:#034b7a ;}
#users-details .dr_y{background-color:#3a5f61 ;}  
#users-details .fo_d{background-color:#fd6500  ;} 
#users-details .way_drop{background-color:#b1a802 ;}
#users-details .no_sts{background-color:#373431 ;}  
#users-details .pick_up{background-color:#840863 ;}
#users-details .packed_up{background-color:#d792bf ;}
#users-details .compl_up{background-color:#3ebd1e ;}
.vis-details{cursor: pointer;}

#orders-list .active-oder {
    background: none  !important;
    border: 2px solid #000;
    color: #000 !important;
}

.laund{background-color:#3a8f8c !important; } 
.dry_clean{background-color:#c9700f !important; } 

.edit-btn{cursor: pointer;}

#layoutSidenav_nav {
    overflow-y: auto;
   /* height: 600px !important;*/
}



#layoutSidenav_nav::-webkit-scrollbar  {
  width: 7px;
}

#layoutSidenav_nav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
#layoutSidenav_nav::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 10px;
}

#layoutSidenav_nav::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}


/* #orders-list .btn:disabled, #orders-list .btn.disabled, #orders-list fieldset:disabled .btn{background: transparent !important;
    border-color: transparent !important;} */
  
/* #orders-list .active-oder {background: #16960a !important;} */

/*************Details page***************/

/*******************/
.details-cloned .customer-dp img {
    max-width: 130px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    margin-bottom: 20px;
}
.details-cloned .customer-basic-details {
    min-width: 240px;
    padding: 20px;
    margin: 5px 0px 25px 0px;
    border-radius: 7px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.details-cloned .customer-dp {
    text-align: right;
    padding: 0px 25px 0px;
}
.details-cloned .details-f {
    display: flex;
    align-items: center;
}
.details-cloned .display-28 {
    margin: 0px 0px 5px !important;
    padding: 0px 25px;
}
.details-cloned .list-unstyled {
    display: inline-block;
    margin-bottom: 18px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border-radius: 7px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.details-cloned .display-26 {
    font-size: 17px;
    font-weight: 600;
}

.rate_ser_right .hist_rate {
    margin: 0 0 0 0 !important;
}
.rate_ser .swc-badge-head {
    display: flex;
    margin: 0 0 0 70px;
}
.order-details-rating {
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
}


#admin-folders-list .modal-footer {
    border-top: none;
    margin: 0 auto;
    position: absolute;
    top: 90px;
    right: 19px;
}
#admin-folders-list{position: relative;}
#admin-folders-list .searc-bar {
    display: flex;
}

#driver-details{padding: 20px 0px;}
#users-details{padding: 20px 0px;}
#orders-list .modal-footer {width: 100%;}
.swc_act .action {
    background: linear-gradient(90deg,#EE3124,#2D2926);}
    .dual-search-heading {
        display: flex;
        align-items: center;
    }
    .dual-search-heading .searc-bar input {
        width: 60%;
    }
    .dual-search-heading .searc-bar{
        width: 80%;
    }

    

.customer-app {
    /* background-image: url(../src/image/mobileContent.png); */
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    padding: 50px 15px;
    margin: 14px 0px;
    background-color: gray;
}

.driver-app {
    /* background-image: url(../src/image/mobileContent.png); */
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    padding: 50px 15px;
    margin: 14px 0px;
    background-color: gray;
}

.folder-app {
    /* background-image: url(../src/image/mobileContent.png); */
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    padding: 50px 15px;
    margin: 14px 0px;
    background-color: gray;
}
.dry-cleaning-app{
    /* background-image: url(../src/image/mobileContent.png); */
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    padding: 50px 15px;
    margin: 14px 0px;
    background-color: gray;
}


.gray-box{
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    padding: 15px;
    margin: 14px 0px;
    background-color: gray;
}

#applications-content .sub_heading_form {
    padding: 8px;
    margin: 15px 1px;
    background: darkgrey;
    border-radius: 10px;
}
.swc-badge-inner.one-time {
    background-color: #e3803a !important;
}
.swc-badge-inner.subsri {
    background-color: #c774c4 !important;
}
/**Media query*/

.badge-head {display: flex;margin: 8px 0 0;}



/************Tracking *************/



@media (max-width:767px){
.track_item li .pick_title{color:#000;padding-bottom:10px;padding-top:19px;}

}
/***************************/



@media(max-width:767px){

.ccsap-inner {grid-template-columns: repeat(2,1fr)  !important;}
.admin-groups-inner .card {max-width: 368px;}
.admin-packages .buy-loads-outer-bottom {grid-template-columns: repeat(2,1fr)  !important;}
.admin-groups-inner {display: grid;gap: 15px;grid-template-columns: repeat(2, 1fr)  !important;}

.admin-groups-inner .card {max-width: 368px;}
.sap-wecard-head {grid-template-columns: repeat(2,1fr) !important;  }
#order_detail_cont .taken_pic {
    flex-direction: column;
}
.swc-dual-btn, .swc-rating {
    display: flex;
    flex-wrap: wrap;
}
#action-modal .swc-dual-btn button {
 
    margin-bottom: 14px;
}
}



@media(max-width:499px){

    .ccsap-inner {grid-template-columns: repeat(1,1fr)  !important;}
     .admin-groups-inner {grid-template-columns: repeat(1, 1fr) !important;}
    .admin-packages .buy-loads-outer-bottom {grid-template-columns: repeat(1,1fr)  !important;}
    .sap-wecard-head {grid-template-columns: repeat(1,1fr)  !important;  }
    #order_detail_cont .no_load {
        flex-direction: column;
    }
    #order_detail_cont .no_load h4:nth-child(2) {
        margin: 10px 0 !important;
    }
    #order_detail_cont  .rate_ser_right h4 {
        margin: 10px 0 0 0;
    }
    .no-margin {
        margin: 0 !important;
    }
    #order_detail_cont  .rate_ser .swc-badge-head {
        margin: 0px 0 0 20px;
    }
    #action-modal .modal-footer {
        justify-content: center !important;
    }
    }
    

    @media(max-width:525px){
    .sap-wecard-head {grid-template-columns: repeat(1,1fr) !important;  }
    .modal-inner-content{
    margin: 0px 5px;
}
    }
    
    
    *:focus,  *:focus-visible {
   outline:none;
}



.min-700{
    
    min-height: 700px;
}

.extra_img img {
    max-width: 150px;
    
}
.extra_img{
    display:inline-block;
    padding:10px;
}



.sap-wecard-inner{background-color:#DCF1FF;padding:20px;border-radius:20px;position:relative;border: 1px solid rgb(0 113 189 / 15%);}
.sap-wecard-head{display:grid;grid-template-columns:repeat(3,1fr);grid-gap:20px;grid-row-gap: 70px;padding: 70px 0 0;}
.swc-mid{display:flex;justify-content:space-between;position: relative;}
.swc-top{display:flex;justify-content:space-between;padding: 0 0 15px;}
.pricing-or-loads{position:absolute;top: -41px;right:30px;background-color: #fec787;padding:5px 10px;min-height:40px;display:flex;align-items:center;justify-content:center;border-top-left-radius:10px;border-top-right-radius:10px;color: #232323;}
.title{font-weight:500;text-transform:capitalize;color:#232323}
.desg{text-transform:uppercase;font-weight:600;letter-spacing:.5px;font-size:10px;color:#0071bd}
.pol-price-loads{padding:0 0 0 5px;font-size:18px;font-weight:500}
.pol-title{font-size:12px;text-transform:capitalize;font-weight: 500;}
.swc-badge-inner{background-color:#232323;color:#fff;padding:5px 10px;line-height:normal;display:flex;align-items:center;justify-content:center;border-radius:30px;font-size:10px;text-transform:uppercase;font-weight:500;letter-spacing:.3px;min-height:26px;margin:0 6px 0 0}
.swc-badge-head{display:flex}
.swc-date, .swc-id{font-weight:500;color:#0071bd;padding: 0 0 4px;}
.swc-u-img,.swc-d-img{height:60px;width:60px;min-width:60px}
.swc-u-img img,.swc-d-img img{height:100%;width:100%;object-fit:cover}
.swc-mid .left-pan,.swc-mid .right-pan{display:flex;flex-direction:column;align-items:center;text-align:center}
.swc-bot{margin:15px 0 0;padding:15px 0 0;border-top:1px solid rgb(0 0 0 / 15%)}
.swc-dual-btn button{background-color:#0071bd;  
    border: 0;
    padding: 8px;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 12px 0 0;
    min-height: 40px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;}
.swc-dual-btn{display:flex}
.swc-rating {display: flex;justify-content: flex-end;color: #f78e1b;}
.swc-mid:before {content: "";position: absolute;width: 100%;max-width: 150px;background-image: linear-gradient(to right, rgb(0 113 189) 33%, rgba(255,255,255,0) 0%);
background-position: bottom;background-size: 11px 2px;background-repeat: repeat-x;display: block;height: 10px;left: 50%;top: 30%;transform: translate(-50%, -50%);}


/*Driver list*/

.swh-cloned-driver-info .swc-dual-btn button{min-height:35px;padding:4px 12px;min-width:35px;font-size:12px;margin:0 6px 0 0}
.swh-cloned-driver-info .swc-dual-btn .btn-dark{background-color:#212529}
.swh-cloned-driver-info .swc-dual-btn .btn-danger{background-color:#bb2d3b}
.swh-cloned-driver-info .swc-dual-btn .btn-success{background-color:#157347}
.swh-cloned-driver-info .swc-mid:before{display:none}
.swh-cloned-driver-info .desg{display:flex;flex-direction:column}
.swh-cloned-driver-info .swc-mid{justify-content:center}
.swh-cloned-driver-info .desg span{padding:3px 0 0}
#drivers-list .sap-wecard-inner {
    background-color: #e4d2be;
}

#drivers-list .drivers-list-content {
    position: relative;
}

#drivers-list .spinner-border {
    position: absolute;
    top: 15%;
    left: 50%;
}

#orders-list .order-list-content {
    position: relative;
}

#orders-list .order-loader {
    position: absolute;
    top: 20%;
    left: 50%;
}

.hist_star {
    display: flex;
}

.extra-load{
    width:90px !important;
    display:inline-block !important;
    margin-right:5px;
}
label.file-load{
    width:140px !important;
    display:inline-block !important;
    text-align: center;
}

.expandable-table{
    width: 100%;
    min-width: 800px;
    background-color:#ffffff;
    overflow:hidden;
}

