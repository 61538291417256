.wec_right_content{border-radius: 10px;border: 1px solid #ECECEC;background: #FFF;padding: 40px;}
.pick_day{display: flex;align-items: center;margin: 0 0 40px 0;}
.pick_day h2{margin: 0;font-size: 28px;font-weight: 700;line-height: 28px;color: #333;}
.pick_day h4{margin: 0 0 0 65px;color: #757575;font-size: 16px;font-weight: 400;line-height: 16px;}
.load{display: flex;align-items: center;justify-content: space-between;}
.load_inner{border-radius: 60px;background: #E8F6FF;display: flex;align-items: center;width: 65%;justify-content: space-between;padding: 15px 24px;}
.load_subinner{display: flex;align-items: center;}
.load h2{color: #333;font-size: 28px;font-weight: 700;line-height: 28px;margin: 0;}
.load_inner h4{color: #0071BD;font-size: 20.354px; font-weight: 600; line-height: 20.354px; margin: 0;}
.load_subinner h2{color: #000;font-size: 16px;font-weight: 500;line-height: 16px;margin: 0;}
.load_subinner h4{color: #0071BD;font-size: 16px;font-weight: 400;line-height: 16px;margin: 0 0 0 20px;}
.check_icon{border-radius: 50px;background: #0474BA; height: 20px;width: 20px;display: flex;justify-content: center;align-items: center;}
.uncheck{background-color: #fff;border: 1px solid #0474BA;}
.track_order .tr_inner {margin: 80px 0 25px 0;color: #333;font-size: 28px;font-weight: 700;line-height: 28px;}
.track_scroll{overflow-x:auto;padding: 0 40px;white-space: nowrap;padding: 0;}
.track_scroll::-webkit-scrollbar {height: 8px;}
.track_scroll::-webkit-scrollbar-track {box-shadow: inset 0 0 5px grey; border-radius: 10px;}
.track_scroll::-webkit-scrollbar-thumb {background:  #979797; border-radius: 10px;}

.add-instruct {color: #0071BD;font-size: 16px;font-style: normal;font-weight: 400;line-height: 16px;text-decoration-line: underline !important;cursor: pointer;}




.track_item li .pick_title {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin: 10px 0px;
  }
  .track_item li p {
    margin: 0;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 0px 8px;
  }
  .track {
       box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background: #eaf1f5; 
    position: relative;
   
          padding: 20px 10px;
    margin: 30px 0px;
    border-radius: 10px;
  }
  .track_item {
    display: flex;
    flex-wrap: nowrap;
    flex-basis: 100%;
    z-index: 1;
  }
  .track_item img {
    padding-top: 14px;
}
  .track_item li {
    width: 100%;
    max-width: 250px;
    
  }
  .track ul{padding:0;}

  

.track .track_item:before {display: none;}
.track .check_icon {position: relative;}
.track .track_item li:before {content: "";background: #cacaca;height: 3px;position: absolute;top: 9px;width: 100%;display: block;}
.track .track_item li.active:before {background: #0474ba;}
.track .track_item {border-radius: 10px;width: max-content;width:100%;}
.track .track_item li {position: relative;margin: 0;}
.track .track_item li:last-child:before {display: none;}



/* media query css code */
  @media (max-width: 420px) {
    /* .navbar .nav-item .nav-link{padding: 0 ;margin: 0 0 25px 0;} */
    .track_item li .pick_title {
      font-size: 11px;
    }
    .track{padding:20px 5px;}
    .track ul{padding:0;}
  }
/* new dashboard css */

/* body{font-family:'Maven Pro',sans-serif;} */

* {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-decoration: none !important;
    scroll-behavior: smooth;
    box-sizing: border-box;
  }
  .sap_blue_btn a {
    border-radius: 40px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    background: #0071bd;
    min-width: 170px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 23px;
  }
  
  /* nav css code */
  /* .navbar{background: #0071BD;}
  .navbar .navbar-brand{font-size: 42px;font-weight: 600;color: #fff !important;line-height:normal;}
  .navbar .nav-item .nav-link{color: #fff !important;font-size: 15px;font-weight: 500;line-height: normal;padding: 0 25px 0 0;}
  .navbar-light .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-toggler {border: 1px solid white !important;}
  .navbar-toggler:focus {box-shadow: none;} */
  
  /* home pack left css code */
  .home_pack_sec {
    padding: 100px 0 0 0;
  }
  .wec_left_content {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 42px 15px;
  }
  .avtar_img {
    text-align: center;
  }
  .pack_das {
    margin: 48px 0 0 0;
    display: flex;
    align-items: center;
    padding: 15px 0 15px 21px;
  }
  .pack_das a {
    color: #000 !important;
  }
  .pack_das.active .das_icon svg path {
    fill: #fff;
  }
  .pack_das.active a {
    color: #fff !important;
  }
  .pack_das.active {
    margin: 48px 0 0 0;
    display: flex;
    align-items: center;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #0474ba;
    padding: 15px 21px;
    cursor: pointer;
  }
  .pack_das a {
    margin: 0 0 0 13px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }
  .pack_acc {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 15px 0 15px 21px;
  }
  .pack_acc.active {
    background-color: #0474ba;
    border-radius: 30px;
  }
  .pack_acc.active a {
    color: #fff;
  }
  .pack_acc.active .btn-primary {
    color: #fff;
  }
  .pack_acc.active svg path {
    fill: #fff;
  }
  .pack_acc a {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: #000;
    margin: 0 0 0 13px;
  }
  .btn-primary {
    color: #000;
    background-color: transparent;
    border: none;
    padding: 0 0 0 12px;
    font-size: 14px;
    font-weight: 600;
  }
  .btn-primary:hover {
    background-color: transparent;
    color: #000;
  }
  .btn-primary:focus {
    color: #000;
    background-color: transparent;
    border-color: none;
    box-shadow: none !important;
  }
  .modal-footer {
    border-top: none;
    margin: 0 auto;
  }
  .modal-header {
    border-bottom: none;
    padding: 0;
  }
  .modal_btns button {
    border-radius: 40px;
    border: 1px solid #0071bd;
    background: #fff;
    padding: 15px 50px;
    color: #0071bd;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
  .modal_btns button:hover {
    background-color: transparent;
    color: #0071bd;
  }
  .modal_btns button:last-child {
    background-color: #0071bd;
    color: #fff;
  }
  .btn-secondary:focus {
    color: #0071bd;
    background-color: transparent;
    border-color: #0071bd;
    box-shadow: none !important;
  }
  .modal-content {
    padding: 20px 0 10px 0;
  }
  
  /* home pack right css code */
  .wec_right_content {
    border-radius: 10px;
    border: 1px solid #ececec;
    background: #fff;
    padding: 40px;
  }
  .pick_day {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 40px 0;
  }
  .pick_day h2 {
    margin: 0;
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    color: #333;
  }
  .pick_day h4 {
    margin: 0 0 0 65px;
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }
  .upg {
    color: #0071BD;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration-line: underline !important;
    cursor: pointer;
}
.combo-upg {
  display: flex;
  align-items: center;
  padding: 40px 0px 25px; 
}
  .load {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .load_inner {
    border-radius: 60px;
    background: #e8f6ff;
    display: flex;
    align-items: center;
    width: 65%;
    justify-content: space-between;
    padding: 15px 24px;
  }
  .load_subinner {
    display: flex;
    align-items: center;
  }
  .load h2 {
    color: #333;
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    margin: 0;
  }
  .load_inner h4 {
    color: #0071bd;
    font-size: 20.354px;
    font-weight: 600;
    line-height: 20.354px;
    margin: 0;
  }
  .load_subinner h2 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
  }
  .load_subinner h4 {
    color: #0071bd;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin: 0 0 0 20px;
  }
  .check_icon {
    border-radius: 50px;
    background: #0474ba;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .uncheck {
    background-color: #fff;
    border: 1px solid #0474ba;
  }
  .track_order .tr_inner {
    margin: 80px 0 25px 0;
    color: #333;
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .track_scroll {
    overflow-x: auto;
    padding: 0 40px;
    white-space: nowrap;
    padding: 0;
  }
  .track_scroll::-webkit-scrollbar {
    height: 8px;
  }
  .track_scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  .track_scroll::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 10px;
  }
  .check_category_inner {
    display: flex;
    margin: 0 0 35px 0;
  }
  
  .check_category h2 {
    color: #333;
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    margin: 0 0 17px 0;
  }
  .choose_box {
    margin: 0 25px 0 0;
  }
  .check_category_inner label {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.5;
  }
  
  /* footer css code */
  /* .foot_sec{background: #333;margin: 100px 0 0 0;}
  .foot_inner{display: flex;flex-wrap: wrap;flex-basis: 100%;align-items: center;}
  .foot_inner{padding: 50px 0 30px  0;margin: 0;}
  .foot_inner li {width: 100%;max-width: 300px;}
  .foot_inner li a{color: #FFF;font-size: 15px;font-weight: 400;line-height: 15px;}
  .foot_inner li .foot_wec{font-size: 42px;font-weight: 600;line-height: normal;}
  .h_line{background: rgba(255, 255, 255, 0.10);height: 1px;}
  .wec_right h4{color: #FFF;text-align: center;font-size: 14px;font-weight: 400;line-height: 14px;margin: 0;padding: 20px 0;}
  .tr_inner{margin: 80px 0 32px 0; color: #333;font-size: 28px;font-weight: 700;line-height: 28px;} */
  
  /* account setting css code */
  .profile_head h2 {
    margin: 0;
    color: #333;
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
  }
  .profile_img {
    margin: 25px 0;
    position: relative;
    max-width: 160px;
  }
  .profile_title {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    flex-basis: 100%;
    padding: 0;
  }
  .profile_title li {
    width: 100%;
    max-width: 295px;
    padding: 0 0 25px 0;
  }
  .profile_title .edit_head {
    color: #333;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
  .profile_title .edit_val {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }
  .wec_right_content .sap_blue_btn a {
    width: 170px;
  }
  .wec_right_content .sap_blue_btn a svg {
    margin: 0 0 0 10px;
  }
  
  /* edit profile css code */
  .edit_circle {
    border-radius: 45px;
    border: 1px solid #000;
    background: #fff;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -10px;
    right: 100px;
  }
  .edit_sec .wec_right_content input {
    padding: 16px 20px;
    border-radius: 60px;
    border: 1px solid #d5d5d5;
    background: #fff;
  }
  .form-control:focus {
    box-shadow: none;
    border-color: #0474ba !important;
  }
  .change_pfield {
    position: relative;
  }
  .change_pfield svg {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  
  /* subscription css code */
  .subscription_sec .pick_day {
    justify-content: space-between;
  }
  .subscription_sec .sap_blue_btn a {
    width: auto;
    background-color: #fff;
    color: #0071bd;
    border: 1px solid #0071bd;
  }
  .subscription_pack h2 {
    margin: 0;
    color: #333;
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
  }
  .subscription_pack {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 38px 0;
  }
  .subscription_pack_inner {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 75%;
  }
  .subscription_pack_inner img {
    width: 35%;
  }
  .subscription_pack_inner h4 {
    margin: 0;
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }
  .subscription_pack_inner p {
    margin: 0;
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }
  .subscription_check {
    display: flex;
    justify-content: end;
    margin: 22px 0 29px 0;
  }
  .subscription_check label {
    color: #333;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
  .subscription_cont .sap_blue_btn a {
    max-width: 239px;
    background: #0071bd;
    color: #fff;
  }
  .form-check-input:focus {
    box-shadow: none;
  }
  
  /* subscription plan css code */
  .subscription_cont .sap_blue_btn a {
    background: #fff;
    color: #0071bd;
  }
  .sub_plan h2 {
    color: #333;
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    margin: 35px 0 33px 0;
  }
  .individual_pack {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
  }
  .load_starimg {
    margin: 0 0 10px 0;
  }
  .individual_pack_inner {
    padding: 45px 20px 25px 20px;
  }
  .individual_pack_inner h2 {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    margin: 0;
  }
  .individual_pack_inner h4 {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    margin: 4px 0;
  }
  .individual_pack .sap_blue_btn a {
    margin: 15px auto;
    font-size: 16px;
  }
  .upfront_amount {
    border-radius: 0px 0px 10px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 10px 0;
    width: 100%;
    max-width: 234px;
    margin: 0 auto;
  }
  .upfront_amount h4 {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    padding: 5px 0px;
  }
  .family_pack {
    background: #dcf1ff;
  }
  .family_pack .sap_blue_btn a {
    background: #0071bd;
    color: #fff;
  }
  .sub_plan_sec_inner .min_load_four {
    background: #0071bd;
  }
  .min_load_four h4 {
    color: #fff;
  }
  
  .mysub_category ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  
  .mysub_category ul li {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 46px;
    white-space: nowrap;
  }
  .mysub_category ul li img {
    padding: 0 20px;
  }
  .load {
    margin: 50px 0 0 0;
  }
  .cat_head {
    color: #333;
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    margin: 0 0 10px 0;
  }
  
  /* notification css code */
  .notific_order {
    display: flex;
    align-items: center;
  }
  .picked_content {
    margin: 0 0 0 28px;
  }
  .picked_content h4 {
    color: #333;
    font-size: 18px;
    font-weight: 600;
    line-height: 31.852px;
    margin: 0 0 5px 0;
  }
  .picked_content p {
    color: #7f7f7f;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin: 0;
  }
  .order_success {
    background-color: #feede8;
    width: 84px;
    height: 84px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notification_cont {
    padding: 0;
  }
  .notification_cont_inner {
    height: 450px;
    overflow-y: scroll;
    padding: 0 40px;
  }
  .notification_cont_inner::-webkit-scrollbar {
    width: 8px;
  }
  .notification_cont_inner::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  .notification_cont_inner::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 10px;
  }
  .notific_head h2 {
    padding: 29px 0 0 40px;
  }
  
  /* history css code */
  .history_img {
    background: url("../../src/image/history-img.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 96px;
    height: 62px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hist_num {
    background-color: #0071bd;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }
  .hist_num {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
  }
  .hist_head h4 {
    color: #0eb100;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin: 0 0 5px 0;
  }
  .history_cont_main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .hist_head {
    margin: 0 0 0 15px;
  }
  .hist_head p {
    color: #000;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
  .history_cont {
    display: flex;
    align-items: center;
  }
  .hist_star {
    display: flex;
  }
  .hist_rate {
    margin: 0 0 0 72px;
  }
  .hist_rate h4 {
    color: #767676;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    margin: 7px 0 0 0;
  }
  .hist_detail a {
    color: #0071bd;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }
  
  /* order detail css code */
  .order_det_head {
    padding: 23px 20px;
    margin: 0;
    justify-content: space-between;
  }
  .order_det_head a {
    color: #0474ba;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline !important;
  }
  .order_detail_cont {
    padding: 0;
  }
  .rate_ser {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
  }
  .rate_ser_left {
    width: 100%;
    max-width: 50%;
    flex-basis: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  .rate_ser_right {
    width: 100%;
    max-width: 50%;
    flex-basis: 100%;
    padding: 20px;
  }
  .rate_ser_left h2 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 15px 0;
  }
  .delever_main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .rate_ser_left h4 {
    color: #0eb100;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin: 13px 0 9px 0;
  }
  .no_load {
    display: flex;
  }
  .no_load h4:nth-child(2) {
    margin: 0 40px;
  }
  .no_load h4 {
    color: #000;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
  .extra_load p {
    color: #666;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 11px 0 0 0;
  }
  .rate_ser_right h4 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 9px 0;
  }
  .rate_ser_right .hist_rate {
    margin: 0 0 16px 0;
  }
  .review {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .review input {
    padding: 10px 0 44px 14px;
    width: 75%;
  }
  .take_pict {
    border-radius: 5px;
    border: 1px dashed #d1d1d1;
    background: #ededed;
    width: 89px;
    text-align: center;
    padding: 12px 0;
    cursor: pointer;
  }
  .take_pict h4 {
    color: #666;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
  }
  .taken_pic {
    display: flex;
  }
  .taken_pic .took_pic:last-child {
    margin: 0 0 0 10px;
  }
  .det_track_order {
    padding: 20px;
  }
  .det_track_order .check_icon {
    border-radius: 50px;
    background: #0474ba;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* chat support css code */
  .chat_head {
    margin: 0;
    padding: 25px;
  }
  .chat_head h2 {
    margin: 0 0 0 40px;
  }
  .chat_supt_cont {
    padding: 0;
  }
  .msg_cont_inner {
    margin: 0 0 10px 0;
  }
  .msg_cont_inner p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    border-radius: 12px;
    background: #f1f1f1;
    display: inline-block;
    padding: 4px 16px;
  }
  .msg_other {
    text-align: right;
  }
  .msg_cont {
    padding: 25px;
  }
  .msg_other p {
    border-radius: 12px;
    background: #0474ba;
    color: #fff;
  }
  .type_main {
    padding: 25px;
  }
  .type_box {
    display: flex;
    align-items: center;
    position: relative;
  }
  .type_box input {
    width: 100%;
    padding: 13px 0 13px 20px;
    margin: 0 0 0 10px;
    outline-color: #0071bd;
    border-radius: 12px;
    border: 2px solid grey;
    background: #fff;
  }
  .sent_icon {
    position: absolute;
    right: 20px;
  }
  .modal_cont h4 {
    margin: 0;
    color: #333;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
  }
  .modal_btn_main {
    display: flex;
    justify-content: center;
    margin: 37px 0 0 0;
  }
  .modal_btn {
    width: 156px;
    height: 50px;
    border: 1px solid #0071bd;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }
  .modal_btn a {
    padding: 15px 50px;
    margin: 0;
    color: #0071bd !important;
  }
  .modal_btn_log {
    background: #0071bd;
    margin: 0 0 0 15px;
  }
  .modal_btn_log a {
    color: #fff !important;
  }
  .modal_cont_main {
    padding: 78px 0;
  }
  .msg_cont_inner:last-child {
    margin: 0;
  }

  .check_category {
    padding: 10px 0px;
}
  
  /* media query css code */
  @media (max-width: 991px) {
    /* .navbar .nav-item .nav-link{padding: 0 ;margin: 0 0 25px 0;} */
    .wec_left_content {
      margin: 0 0 40px 0;
    }
    /* .foot_inner li {max-width: 348px; padding: 0 0 15px;} */
    .subscription_pack_inner {
      width: 70%;
    }
    .rate_ser_left {
      max-width: 100%;
    }
    .rate_ser_right {
      max-width: 100%;
    }
    .rate_ser_left {
      border-right: none;
    }
  }
  
  @media (max-width: 767px) {
    .pick_day {
      align-items: start;
      flex-direction: column;
    }
    .pick_day h4 {
      margin: 0px 0 0 26px;  
    }
    .load {
      align-items: start;
      flex-direction: column;
      margin: 70px 0 0;
    }
    .load_inner {
      align-items: start;
      flex-direction: column;
      width: 65%;
      padding: 15px;
      width: 100%;
      margin: 30px 0 0;
      border-radius: 20px;
      align-items: center;
    }
    .load_subinner {
      margin: 12px 0 0 0;
      flex-direction: column;
    }
    .load_subinner h4 {
      margin: 12px 0 0 0;
    }
    .foot_inner li {
      max-width: 200px;
    }
    .profile_title li {
      max-width: 280px;
    }
    .wec_right_content {
      height: auto;
    }
    .wec_right_content {
      padding: 15px;
    }
    .subscription_pack {
      flex-direction: column;
      align-items: start;
    }
    .subscription_pack_inner {
      width: 100%;
      flex-direction: column;
      align-items: start;
    }
    .pick_day h2 {
      margin: 0 0 12px 0;
    }
    .subscription_pack h2 {
      margin: 0 0 12px 0;
    }
    .subscription_pack_inner img {
      width: 100%;
      margin: 12px 0;
    }
    .family_pack {
      margin: 15px 0 0 0;
    }
    .picked_content h4 {
      font-size: 17.773px;
      line-height: 20.852px;
    }
    .notific_order {
      flex-direction: column;
    }
    .picked_content p {
      font-size: 16px;
    }
    .picked_content {
      margin: 0;
    }
    .notification_cont {
      height: 688px;
    }
    .picked_icon {
      margin: 0 0 10px 0;
    }
    .order_success {
      margin: 0 0 10px 0;
    }
    .notification_cont {
      padding: 0;
    }
    .history_cont {
      align-items: start;
      flex-direction: column;
    }
    .hist_head {
      margin: 10px 0 0 0 !important;
    }
    .hist_rate {
      margin: 9px auto !important;
    }
    .history_cont_main {
      flex-direction: column;
      text-align: center;
    }
    .history_img {
      margin: 0 auto;
    }
    .review {
      flex-direction: column;
      align-items: start;
    }
    .review input {
      width: 100%;
    }
    .take_pict {
      margin: 12px 0 0 0;
    }
    .delever_img {
      margin: 5px;
    }
    .order_detail_cont {
      padding: 0;
    }
    .chat_supt_cont {
      padding: 0;
    }
    .home_pack_sec {
      padding: 50px 0 0 0;
    }
    .foot_sec {
      margin: 50px 0 0 0;
    }
    .modal_btns button {
      padding: 8px 25px;
    }
    .check_category_inner {
      flex-direction: column;
      margin: 0;
    }
    .check_category_inner .choose_box {
      margin: 0 0 12px 0;
    }
    .sap_blue_btn a {
      font-size: 14px;
    }
    .wec_right_content {
      padding: 15px 15px 30px;
    }
    .track_order .tr_inner {
      margin: 65px 0 45px;
    }
   
  }
  
  .check_category_inner .form-check-input:focus {
    box-shadow: none;
}


.rdt_TableBody{
  display: flex !important ;
  flex-direction: row !important ;
  justify-content: space-around !important ;
  align-items: center !important ;
  flex-wrap: wrap !important;

}
.rdt_TableRow{
  position: relative;
  overflow: hidden;
}
.status_bar{
 span{
  width: 44%;
  position: absolute;
  top: 0px;
  height: 6px;
  border-radius: 10px ;
 }


.status_span{
  left: 5px;
  background: gray;
  
}

.status_span:hover,.instrunction_span:hover{
 
  span{
    display: block !important;
    top: 8px;
  background-color: #525252;
  height: fit-content;
  color: white;
  text-align: center;
  z-index: 2;
  padding: 6px 4px;
  width: 100%;

  }
}
 

}

.instrunction_span{
  /* background-color: red; */
  right: 15px;
  width: 44% !important;
    
   }
.light_heading{
  font-size: 13px;
  font-weight: bold;
  color: gray;
  margin-bottom: 8px;
  overflow: visible !important;
}

.card_item_text{
  font-weight: bold;
  font-size: 14px;
  color: rgb(20, 20, 20);
  overflow: visible !important;
  margin-left: 2px;
}


.card_data{
  display: flex;
}

.searc-bar input{
  border: none !important;
  border-bottom: 1px solid #ccc !important ;
  text-align: start !important;
  height: 40px !important;
  font-size: 12px !important;
  border-radius: 0px !important;
}



@media screen and (max-width: 1200px) {
  .rdt_TableRow {
   width: 45% !important;
   .card_item_text{

     text-wrap: wrap;
   }
  }
}


@media screen and (max-width: 850px) {
  .card_data {
   flex-direction: column;
  }
}


@media screen and (max-width: 500px) {
  .rdt_TableRow {
    width: 90% !important;
    
   }
}

.status_bar{
  cursor: pointer;
  white-space: normal !important;}
.order-status .searc-bar{
  display: none;
}


.credit-table{

  .react-datepicker-wrapper{
    display: none;
  }
}



/* ..... road map css   */


.roadmap-container{float:left;width:100%;padding:50px 0;font-family:"Stag Sans Round",sans-serif;font-size: 16px;border-top:5px solid #159CDF;}
.roadmap-auto{max-width:950px;margin:0 auto;}
/* .roadmap-container .roadmap-h2{background:url(../template/images/underline-background.png) center bottom no-repeat;background-size:250px 3px;color:#159CDF;z-index:1;font-family:"Stag Sans Round",sans-serif;font-size:45px;line-height:55px;font-weight:500;position:relative;margin-bottom:20px;padding-bottom:6px;text-align: center;} */
.roadmap-container .roadmap-timeline{padding-top:30px;max-width:1000px;margin:0 auto;}
.roadmap-container .timeline-dot{position: absolute;left:50%;z-index:3;width:8px;height:8px;margin-left:-6px;border:2px solid #1BACB3;border-radius:6px;background-color:#1BACB3;}
.roadmap-container .timeline-list{float:left;width:100%;position: relative;}
.roadmap-container .timeline-list .timeline-list-col{float:left;width:50%;padding-top:50px;padding-right:0;padding-left:0;position:relative;z-index: 3;}
.roadmap-container .timeline-list .timeline-item-block{width:350px;margin-bottom:50px;float:left;text-align: left;}
.roadmap-container .timeline-list .timeline-item-block17q4{margin-top:50px;}
.roadmap-container .timeline-list .timeline-item-block18q2{margin-top:50px;}
.roadmap-container .timeline-list .timeline-item-block .timeline-item-time{position:relative;width:320px;min-height:36px;border-bottom:0 solid #1BACB3;}
.roadmap-container .timeline-list .timeline-item-block .timeline-item-label{float:left;font-size:24px;line-height:26px;font-weight:500;text-align:left;text-transform:uppercase;color:#222;}
.roadmap-container .timeline-list .timeline-item-block .timeline-item-content{position:relative;left:0;width:280px;margin-top:0;margin-right:40px;float:left;width:100%;font-size:16px;line-height:21px;color:#555;font-weight:400;text-transform:none;border:1px solid #ddd;border-top:0;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;padding:10px;padding-bottom:0;}
/* .roadmap-container .timeline-list .timeline-item-block .timeline-item-content p{background:url(../template/images/arrow-dot-gray2.png) 0 2px no-repeat;margin-bottom:10px;background-size:16px 16px;padding-left:24px;} */
.roadmap-container .timeline-list .timeline-item-block .timeline-item-content p.no-bullet{background:none;padding-left:0;}
.roadmap-container .timeline-list .timeline-col-right{padding-top:150px;}
.roadmap-container .timeline-list .timeline-col-right .timeline-item-block{float:right;}
.roadmap-container .timeline-list .timeline-col-right .timeline-item-label{float:right;text-align: right;}
.roadmap-container .timeline-list .timeline-col-right .timeline-item-content{text-align: left;margin-left:0px;margin-right:0;}
.roadmap-container .timeline-list .timeline-list-vr{width:0;height:100%;border-left:1px solid #1BACB3;border-right:1px solid #1BACB3;position: absolute;left:50%;margin-left:-1px;z-index: 2l}

.roadmap-container .timeline-list .timeline-item-label-hr{float:left;width:475px;height:3px;background-color: #555;position: relative;}
.roadmap-container .timeline-list .timeline-item-block .item-dot{left:auto;right:0;bottom:-5px;margin-right:-6px;margin-left:0;}
.roadmap-container .timeline-list .timeline-col-right .item-dot{left:0;right:auto;margin-right:0;margin-left:-7px;}

.roadmap-container .timeline-list .timeline-col-right .timeline-item-label-hr{margin-left:-125px;}

.roadmap-container .timeline-list .timeline-item-block .timeline-item-label2{font-size:18px;line-height:24px;font-weight:400;text-align:left;text-transform:uppercase;color:#fff;padding:7px 10px 6px 10px;}
.roadmap-container .timeline-list .timeline-item-block-green .timeline-item-label2{background-color:#78BE20;}
.roadmap-container .timeline-list .timeline-item-block-green .timeline-item-label-hr{background-color:#78BE20;}
.roadmap-container .timeline-list .timeline-item-block-green .item-dot{background-color:#78BE20;border-color:#fff;}

.roadmap-container .timeline-list .timeline-item-block-green2 .timeline-item-label2{background-color:#26A3AA;}
.roadmap-container .timeline-list .timeline-item-block-green2 .timeline-item-label-hr{background-color:#26A3AA;}
.roadmap-container .timeline-list .timeline-item-block-green2 .item-dot{background-color:#26A3AA;border-color:#fff;}

.roadmap-container .timeline-list .timeline-item-block-blue .timeline-item-label2{background-color:#159CDF;}
.roadmap-container .timeline-list .timeline-item-block-blue .timeline-item-label-hr{background-color:#159CDF;}
.roadmap-container .timeline-list .timeline-item-block-blue .item-dot{background-color:#159CDF;border-color:#fff;}

.roadmap-container .timeline-list .timeline-item-block-blue2 .timeline-item-label2{background-color:#00507F;}
.roadmap-container .timeline-list .timeline-item-block-blue2 .timeline-item-label-hr{background-color:#00507F;}
.roadmap-container .timeline-list .timeline-item-block-blue2 .item-dot{background-color:#00507F;border-color:#fff;}

.roadmap-container .timeline-list .timeline-item-block-violet .timeline-item-label2{background-color:#890C58;}
.roadmap-container .timeline-list .timeline-item-block-violet .timeline-item-label-hr{background-color:#890C58;}
.roadmap-container .timeline-list .timeline-item-block-violet .item-dot{background-color:#890C58;border-color:#fff;}

.roadmap-container .timeline-list .timeline-item-block-mobile{display: none;}




@media screen and (max-width:950px){
  
	.roadmap-container{padding:22px 0;}
	.spacer-block{margin-bottom:0;}
	.roadmap-container .roadmap-h2{font-size:25px;line-height:30px;background-size:150px 3px;}
	.roadmap-container .timeline-list .timeline-list-vr{left:20px;}
	.roadmap-container .timeline-dot{left:20px;}
	.roadmap-container .roadmap-timeline{padding-top:0;}
	.roadmap-container .timeline-list .timeline-list-col{float:none;width:auto;margin-left:25px;padding-left:25px;margin-right:25px;}
	.roadmap-container .timeline-list .timeline-item-block-mobile{display: block;}
	.roadmap-container .timeline-list .timeline-item-block .timeline-item-time{width:auto;}
	.roadmap-container .timeline-list .timeline-item-label-hr{float:none;width:auto;margin-left:-30px;}
	.roadmap-container .timeline-list .timeline-item-block .timeline-item-label{float:none;}
	.roadmap-container .timeline-list .timeline-item-block .item-dot{left:0;right:auto;margin-left:-6px;margin-right:0;}
	.roadmap-container .timeline-list .timeline-item-block .timeline-item-label2{width:auto;}
	.roadmap-container .timeline-list .timeline-item-block .timeline-item-content{float:none;width:auto;margin-right:0;}
	.roadmap-container .timeline-list .timeline-col-right{display: none;}
	.roadmap-container .timeline-list .timeline-item-block{float:none;width:auto;}

}