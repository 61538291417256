body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.game {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.game-board {
  border: 1px solid #000;
}

.board-row {
  display: flex;
}

.square {
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}

.game-info {
  margin-left: 20px;
}


showCalendar .rdrCalendarWrapper > div:not(:first-child) {
display: none;
}

showCalendar .rdrCalendarWrapper > div:first-child{
min-width: 300px;
}