
/* Multiple calendar */
.myCalendar{vertical-align: top;}
.rmdp-week-days{    color: #0c63e4;width:100%;font-size:14px;font-weight:600;letter-spacing:0;line-height:17px;margin:0;text-align:center;text-transform:uppercase}
.rmdp-calendar{padding:25px}
.rmdp-week:first-child{border-top:1px solid #e4e5e7;;padding:20px 0;}
.rmdp-header-values{color:#0073b9;font-size:17px;font-weight:600;letter-spacing:.01em;line-height:21px;text-align:left!important;margin-left:0}
.rmdp-header{padding:10px !important;margin-top: 0 !important;background-color: #FFD700}
.rmdp-panel.right{display:none!important}
.rmdp-shadow{box-shadow:0 0!important;border:1px solid rgba(0,0,0,.1)!important;display: inline-block; margin: 5px;vertical-align: top; min-height: 300px;width:80%;}
.double .rmdp-shadow{width:48%;}
.rmdp-day{width:100%;height:35px;}
.rmdp-day span{border-radius:50px;height:30px;font-size:16px;font-weight:500;letter-spacing:0;margin:0;text-align:center;color:#232323;transition:all .2s ease-in-out}

.rmdp-calendar{padding:0 !important;border:0;width:100%;}

.rmdp-day.rmdp-selected span:not(.highlight){box-shadow:0 0}
.rmdp-day-picker{padding:0 15px !important;gap: 20px;display:block !important;}
.calendar{margin:15px auto}
.rmdp-header-inner{position: relative; display: flex; align-items: center;}
.cursor-pointer{cursor: pointer;color: #194376;}
.rmdp-day.selected span{background-color: #194376;color: #fff}

.rmdp-day.disabled span{background-color: #ddd;text-decoration: line-through !important;}




.tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #7ea6f0;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 35px;
}

.mytooltip:hover .tooltiptext {
  visibility: visible;
}
.rmdp-week:first-child span, .rmdp-week-day {
    width:100%;
    font-weight: bold;
    color: #0c63e4;
}

 @media (max-width:767px) {
     .rmdp-shadow{
         width:100%
     }
 }


 @media (max-width:375px) {
.my-shadow{min-height: 220px;margin: 5px;}
.my-calendar{padding:10px;}
.my-day{width: 25px; height: 25px;}
.my-day{width: 25px; height: 25px;}
.rmdp-day span{   
    height: 20px;
    font-size: 14px;}
.rmdp-day-picker{padding:0 !important;gap: 20px;}
}



