.place-order .category-content {box-shadow: none;padding: 7px 0px;}

.place-order .place-order-hd .payment-method input[type="radio"] {position: absolute;top: 21px;margin: 0;left: 30px;}
.place-order .place-order-hd .payment-method {max-width: 268px;height: 57px;border-radius: 94px;border: 1px solid #EFEFF4;background: #FFF;
    display: flex;align-items: center;position: relative;width: 100%;margin: 0px 11px;}
.place-order .place-order-hd .payment-method label { margin-left: 43px;padding: 10px 30px 10px 15px !important;
    display: block;position: relative;color: #262628;font-family: 'Maven Pro',sans-serif; font-size: 14px; font-style: normal;
    font-weight: 400;line-height: normal;width: 100%;}
.place-order .place-order-hd .click-option {display: flex;justify-content: center;padding: 25px 0px 30px;}



/***************/
@media(max-width:434px) {
    .place-order .place-order-hd .click-option {display: block;}
    .place-order .place-order-hd .click-option .payment-method{ margin: 0 auto 20px;}
    
    
    }
    
    
    
    
.prices{
        color: #555;
    font-size: 16px;
    font-weight: 500;
}


label.min-label{min-width: 136px;}


.form-inner .weekDays-selector input {
/*  display: none!important;*/
}


.form-inner .weekDays-selector label {
display: inline-block;
    border-radius: 6px;
    background: #dddddd;
    height: 40px;
    width: 79px;
    margin-right: 6px;
    margin-bottom: 6px;
    text-align: center;
    cursor: pointer;
    padding: 10px 0px;
}
.form-inner.step-outer{
    min-height: auto;
    margin-bottom: 10px;
    margin-top: 10px;
}

.form-inner .weekDays-selector label:has(input:checked) {
  background: #07bc0c;
  color: #ffffff;
}
    
.form-inner .weekDays-selector label input{
    
    accent-color: #fff;
    
}

.bold-text{
    font-weight: 600 !important;
    color:#045cb4 !important;
    
}
    




.steps {
  padding-left: 0;
  list-style: none;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 12px;
  line-height: 1;
  margin: 0px auto;
  border-radius: 3px;
  width:100%;
      border-radius: 8px;
    overflow: hidden;
  strong {
    font-size: 20px;
    display: block;
    line-height: 1.4;
    text-transform: uppercase;
  }
  > li {
    position: relative;
    display: block;
    padding: 5px 5px 5px 40px;
    width: 50%;
    height: 65px;
   cursor:pointer;
   text-align: left;
   font-size: 18px;
  }
}

@media (min-width: 601px) {
  .steps {
    > li {  float: left;   }
    .item {
      color: #333;
      background: #ccc;
    }
    .active {
      color: #194376;
      background: #FFD700;
    }
    
    li {
      > span {
        &:after, &:before {
          content: "";
          display: block;
          width: 0px;
          height: 0px;
          position: absolute;
          top: 0;
          left: 0;
          border: solid transparent;
          border-width: 30px;
        }
        &:after {
          top: -5px;
          z-index: 1;
          border-left-color: white;
          border-width: 34px;
        }
        &:before {
          z-index: 2;
        }
      }
      &.item + li > span:before {
        border-left-color: #ccc;
      }
      &.active + li > span:before {
        border-left-color: #FFD700;
      }
      &:first-child {
        > span {
          &:after, &:before {
            display: none;
          }
        }
        i {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          border: solid transparent;
         
          border-width: 30px;
        }
      }
      &:last-child i {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        border: solid transparent;
        border-left-color: white;
        border-width: 30px;
        left: auto;
        right: -30px;
        border-left-color: transparent;
      }
    }
  }
  /* Arrows at start and end */
}
    
    
    
  
@media (max-width: 600px) {
  .steps { width: 100%;
    > li {  float: left; width:50%;  font-size:14px; padding:5px 15px;line-height: 1.2}
    .item { color: #333;  background: #ccc;  }
    .active {  color:#045cb4;background: #FFD700; }   
    li {
      > span strong {
        font-size: 17px;
        padding-bottom: 5px;
      }
      
      &:first-child {
        > span {
          &:after, &:before {
            display: none;
          }
        }
        i {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          border: solid transparent;
         
          border-width: 30px;
        }
      }
      &:last-child i {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        border: solid transparent;
        border-left-color: white;
        border-width: 30px;
        left: auto;
        right: -30px;
        border-left-color: transparent;
      }
    }
  }
  /* Arrows at start and end */

  
  @media (max-width: 350px) {
   .steps > li {font-size:13px; padding:5px 0 0 10px;}
  }
  
.bold-text{
    font-size:13px;
}
.form-inner label{
    font-size:14px;
}
.place-order .mb-4{
    margin-bottom: 0.5rem!important;
}
.form-inner .radio-btn-group .radiobtn, .radio-btn-group{
    display:flex
}
}



@media (max-width: 360px) {
    
.bold-text{
    font-size:11px;
}

}

.sap-light-btn button,.sap-light-btn button, #card-button{
    padding: 12px;
    margin: 0 8px;
  }
  .sq-card-iframe-container, .sq-card-iframe-container iframe{
      
      max-height: 50px;
  }
  
  @media (max-width: 767px) {
 
      .sq-card-iframe-container, .sq-card-iframe-container iframe{
      
      max-height: 90px;
  }
    
   }
  
  
  .card-token .card-token-label{
         color: #194376;
    vertical-align: top;
    font-size: 14px;
    display: inline-block;
    padding: 0 10px;
      
  }


  
  .card-token .card-token-trash {
            color: #194376;
    padding-right: 20px;
    font-size: 25px;
    padding-top: 0px;
    margin-top: -5px;
  }
  
  .form-check .form-check-input{
      
  }
  .card-token .form-check  .form-check-input{
      margin-left:5px;
  }
  .agree{margin-top: 13px; margin-right: 10px;}
  .form-inner label.order-label{
     font-size: 1rem;
    font-weight: 500;
    line-height: 2;
    color: #000; 
     padding:0;
  }
  .form-inner label.order-value{
      font-size: 0.9375rem;
    font-weight: 500;
    line-height: 2;
    color: #045cb4;
    padding:0;
    text-transform: none;
  }
  
  
  
  
  
    